import { z } from 'zod';

export const MCPToolRequestPayloadSchema = z.object({
    toolId: z.string(),
    userId: z.string(),
    arguments: z.record(z.string(), z.any()).optional(),
});

export type MCPToolRequestPayload = z.infer<typeof MCPToolRequestPayloadSchema>;

export enum MCPToolIds {
    GeneratePrd = 'generate_prd',
    GeneralQuery = 'general_query',
    TestTool = 'test_tool',
}

export const MCPToolResponseSchema = z.object({
    content: z.string(),
});

export type MCPToolResponse = z.infer<typeof MCPToolResponseSchema>;

export const MCPToolGeneralQuerySchema = z.object({
    query: z.string(),
});

export type MCPToolGeneralQuery = z.infer<typeof MCPToolGeneralQuerySchema>;

export const MCPToolGeneratePrdSchema = z.object({
    featureName: z.string(),
});

export type MCPToolGeneratePrd = z.infer<typeof MCPToolGeneratePrdSchema>;
